const BASE_URL = "https://www.ondebrief.com";

export const generateStructuredDataForArticle = (lang, article, mainPage) => {
  let images = [];
  if (article.coverImageResized.structuredData) {
    images = Object.values(article.coverImageResized.structuredData);
  } else {
    images.push(article.coverImageResized.large);
  }

  const articleUrl = `${BASE_URL}/${lang}${article.url}`;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ReportageNewsArticle",
    headline: article.title,
    alternativeHeadline: article.shortTitle,
    image: images,
    datePublished: article.publishedDate, // Replace with actual publish date if available
    dateModified: article.publishedDate, // Replace with actual modified date if available
    url: articleUrl,
    inLanguage: lang,
    articleSection: "Sports",
    sport: "Football",
    author: {
      "@type": "Organization",
      name: "Ondebrief",
    },
    publisher: {
      "@type": "Organization",
      name: "Ondebrief",
      logo: {
        "@type": "ImageObject",
        url: "https://www.ondebrief.com/odb_rect_logo.png",
      },
    },
  };

  if (mainPage) {
    delete structuredData.url;
    structuredData.mainEntityOfPage = {
      "@type": "WebPage",
      "@id": articleUrl,
    };

    structuredData.articleBody = article.components
      .filter(({ type }) => type === "Paragraph")
      .map(({ text }) => text)
      .join(" ");
  }

  return structuredData;
};

export const generateStructuredDataForArticleCollection = (
  url,
  lang,
  articles
) => {
  if (lang === "fr") {
    return null;
  }

  return {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    name: "England Premier League Match Reports",
    description:
      "Latest match reports and updates from the England Premier League.",
    url,
    inLanguage: lang,
    hasPart: articles.map((article) =>
      generateStructuredDataForArticle(lang, article)
    ),
  };
};

export const generateStructuredDataForFixturePage = (
  url,
  competitionName,
  roundName,
  homeTeamName,
  awayTeamName,
  startDate,
  season,
  matchDetails,
  venue = {}
) => {
  const seasonCompetition = `${season}/${season + 1} ${competitionName}`;
  return {
    "@context": "https://schema.org",
    "@type": "SportsEvent",
    name: `${homeTeamName} vs ${awayTeamName} - ${seasonCompetition} - ${roundName} | ${matchDetails}`,
    startDate,
    url: url,
    sport: "Football",
    competitor: [
      {
        "@type": "SportsTeam",
        name: homeTeamName,
        sport: "Football",
      },
      {
        "@type": "SportsTeam",
        name: awayTeamName,
        sport: "Football",
      },
    ],
    location: {
      "@type": "StadiumOrArena",
      name: venue.name,
      address: {
        "@type": "PostalAddress",
        addressLocality: venue.city,
      },
    },
    about: {
      "@type": "SportsSeason",
      name: seasonCompetition,
    },
  };
};
