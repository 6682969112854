import { Helmet, HelmetProvider } from "react-helmet-async";

const PageHead = ({
  title,
  description,
  canonical,
  hreflangs = [],
  preloadLinks = [],
  structuredData,
}) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta charSet="UTF-8" />
        {description && <meta name="description" content={description} />}
        {canonical && <link rel="canonical" href={canonical} />}
        {hreflangs.map(({ lang, url }, index) => (
          <link key={index} rel="alternate" hrefLang={lang} href={url} />
        ))}
        {preloadLinks.map(({ href, as, type, crossOrigin }, index) => (
          <link
            key={`preload-${index}`}
            rel="preload"
            href={href}
            as={as}
            type={type}
            crossOrigin={crossOrigin}
          />
        ))}
        {structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>
    </HelmetProvider>
  );
};

export default PageHead;
