import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "antd";
import { Grid } from "grommet";
import { useTranslation } from "react-i18next";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import useMediaScreen from "utils/hooks/useMediaScreen";
import NewsCard from "./NewsCard";
import TopNewsCard from "./TopNewsCard";

import { fetchData } from "api/news.api";
import SuspenseFallback from "@components/core/modules/SuspenseFallback";
import PageHead from "@components/core/modules/PageHead";
import { generateStructuredDataForArticleCollection } from "utils/seo.utils";

const ITEM_PER_ROW_BY_SIZE = {
  small: 1,
  medium: 1,
  large: 2,
};
const GAP_BETWEEN_ITEMS = {
  small: 0,
  medium: {
    row: "small",
    column: "large",
  },
  large: {
    row: "medium",
    column: "large",
  },
};

const formatUrl = (articleId, title) => {
  return `/article/${
    title
      .toLowerCase()
      .normalize("NFD") // Normalize accented characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/[^a-z0-9\s-]/g, "") // Remove all non-alphanumeric except spaces & hyphens
      .trim() // Remove leading/trailing spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-") // Replace multiple hyphens with a single hyphen
  }/${articleId}`;
};

const BASE_URL = "https://www.ondebrief.com";

const News = () => {
  const { lang } = useParams();
  const [topArticle, setTopArticle] = useState(null);
  const [articles, setArticles] = useState();
  const [loading, setLoading] = useState(true);
  const { isLarge, isMedium } = useMediaScreen();
  const { t } = useTranslation();

  let size = "small";
  let responsivePadding = "0px 25px 20px 25px";

  if (isMedium) size = "medium";
  if (isLarge) size = "large";

  if (size === "medium") responsivePadding = "0px 35px 0px 35px";
  if (size === "large") responsivePadding = "0px 25px 0px 25px";

  useEffect(() => {
    const getArticles = async () => {
      let res = await fetchData();

      res = res.map((article) => ({
        ...article,
        url: formatUrl(article.id, article.title),
        context: `${article.sport}${
          article.articleContext ? `, ${article.articleContext}` : ""
        }`,
      }));

      setTopArticle(res.shift());
      setArticles(res);
      setLoading(false);
    };

    getArticles();
  }, []);

  if (loading) {
    return <SuspenseFallback />;
  }

  return (
    <Layout style={styles.container}>
      <PageHead
        title={t("news.title")}
        description={t("news.title")}
        canonical={`${BASE_URL}/${lang}/${t("routes.news")}`}
        hreflangs={[
          { lang: "en", url: `${BASE_URL}/en/news` },
          { lang: "fr", url: `${BASE_URL}/fr/infos` },
          { lang: "x-default", url: `${BASE_URL}/en/news` },
        ]}
        structuredData={
          topArticle && articles
            ? generateStructuredDataForArticleCollection(
                `${BASE_URL}/${lang}/${t("routes.news")}`,
                lang,
                [topArticle, ...articles]
              )
            : null
        }
      />

      <Layout.Content
        style={{
          ...styles.content,
        }}
      >
        {topArticle && (
          <TopNewsCard
            url={topArticle.url}
            size={size}
            articleId={topArticle.id}
            title={topArticle.shortTitle || topArticle.title}
            coverImages={
              topArticle.coverImageResized.responsive ||
              topArticle.coverImageResized
            }
            description={topArticle.shortContent}
            context={topArticle.context}
          />
        )}

        <LazyLoadComponent>
          <Grid
            style={{
              padding: responsivePadding,
            }}
            columns={{ count: ITEM_PER_ROW_BY_SIZE[size], size: "auto" }}
            gap={GAP_BETWEEN_ITEMS[size]}
          >
            {articles &&
              articles.map((article) => (
                <NewsCard
                  key={article.id}
                  url={article.url}
                  articleId={article.id}
                  size={size}
                  title={article.shortTitle || article.title}
                  coverImages={
                    article.coverImageResized.responsive ||
                    article.coverImageResized
                  }
                  description={article.shortContent}
                  context={article.context}
                />
              ))}
          </Grid>
        </LazyLoadComponent>
      </Layout.Content>
    </Layout>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    background: "#f8f9fa",
  },
  content: {
    margin: "auto",
    width: "100%",
    height: "auto",
    maxWidth: "1000px",
    background: "#f8f9fa",
  },
};

export default News;
