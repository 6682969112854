import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImagePLaceholder from "../ImagePlaceholder";

const ArticleImage = ({
  logoSize,
  placeholderStyle,
  style,
  coverImages,
  threshold,
  loadingType,
}) => {
  const srcSet = coverImages
    ? `${coverImages.small} 200w, ${coverImages.medium} 500w, ${coverImages.large} 1280w`
    : null;
  const [loading, setLoading] = useState(true);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        minHeight: "100%",
      }}
    >
      {loading && (
        <div style={placeholderStyle}>
          <ImagePLaceholder logoSize={logoSize} />
        </div>
      )}
      <LazyLoadImage
        alt=""
        style={style}
        threshold={threshold || 100}
        loading={loadingType}
        srcSet={srcSet}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default ArticleImage;
