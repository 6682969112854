import { Layout } from "antd";

import logo from "../ondebrief_logo.png";
import LocalizedLink from "@i18n/LocalizedLink";

const { Header } = Layout;

const SmallHeader = () => (
  <Header style={styles.header}>
    <div style={styles.content}>
      <LocalizedLink to="matches-calendar">
        <img src={logo} alt="logo-ondebrief" style={styles.logo} />
      </LocalizedLink>
    </div>
  </Header>
);

const styles = {
  header: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    width: "100%",
    borderBottom: "1px solid #eff0f2",
    height: 50,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 50,
  },
  logo: {
    height: "16px",
    fontSize: 24,
    lineHeight: "24px",
  },
};

export default SmallHeader;
