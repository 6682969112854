import axios from "axios";

const API_ENDPOINT = "https://www.ondebrief.com/api/v7";

const callAPI = async (path, params) => {
  return (
    await axios.get(API_ENDPOINT + path, {
      params,
    })
  ).data;
};

class FootballAPI {
  async getCompetition(id, lang) {
    const result = await callAPI("/competition", {
      competitionId: id,
      lang,
    });

    return result;
  }

  async getFixturesAndStages(competitionId, round, lang) {
    const result = await callAPI("/fixtures-rounds", {
      competitionId,
      round,
      lang,
    });

    return result;
  }

  async getFixture(id, lang) {
    return await callAPI("/fixtures", { id, lang });
  }

  async getCompetitions(lang) {
    return await callAPI("/competitions", { lang });
  }

  async getStandings(competitionId, season, lang) {
    const params = {
      competitionId,
      season,
      lang,
    };

    return callAPI("/standings", params);
  }

  async getFixtures(competitionId, season, round, lang) {
    const params = {
      competitionId,
      season,
      round,
      lang,
    };

    return callAPI("/fixtures", params);
  }

  async getFixturesByDate(date, lang) {
    const params = {
      date,
      lang,
    };

    return callAPI("/fixtures", params);
  }

  async getCompetitionStages(competitionId, season, lang) {
    const params = {
      competitionId,
      season,
      lang,
    };

    return callAPI("/rounds", params);
  }
}

const footballApi = new FootballAPI();

export default footballApi;
