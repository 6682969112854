import { Box } from "grommet";
import LocalizedLink from "@i18n/LocalizedLink";

import stylesPerSize from "./stylesPerSize";
import ArticleImage from "@components/core/modules/ArticleImage";

const NewsCard = ({ url, size, title, coverImages, context }) => {
  const styles = stylesPerSize[size];

  return (
    <LocalizedLink to="article" url={url}>
      <Box style={styles.container}>
        <div style={styles.subContainer}>
          <div style={styles.coverImage}>
            <ArticleImage
              alt=""
              style={styles.coverImage}
              logoSize={20}
              threshold={100}
              coverImages={coverImages}
            />
          </div>
          <div style={styles.aboutContainer}>
            <div style={styles.aboutSubContainer}>
              <span style={styles.aboutTitle}>{context}</span>
            </div>
            <h2 style={styles.title}>{title}</h2>
          </div>
        </div>
      </Box>
    </LocalizedLink>
  );
};

export default NewsCard;
