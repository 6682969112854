import React from "react";
import { Routes, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LocalizedRoutes = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();

  // Function to check if a React component has children
  function hasChildren(component) {
    return (
      component.props &&
      component.props.children !== null &&
      component.props.children !== undefined
    );
  }

  const transformChild = (parentChild) => {
    if (!hasChildren(parentChild)) {
      return React.cloneElement(parentChild, {
        ...parentChild.props,
        path: localizeRoutePath(parentChild.props.path),
      });
    } else {
      let transformedChildren = Array.isArray(parentChild.props.children)
        ? React.Children.map(parentChild.props.children, transformChild)
        : transformChild(parentChild.props.children);

      return React.cloneElement(parentChild, {
        ...parentChild.props,
        children: transformedChildren,
        path: localizeRoutePath(parentChild.props.path),
      });
    }
  };

  /**
   * @param path can be string, undefined, or string array
   * @returns Localized string path or path array
   */
  function localizeRoutePath(path) {
    if (!path || path.startsWith(":") || path === "/") return path;
    return t(path);
  }

  // Prevent forced locale redirection (fix for Googlebot issues)
  const currentPath = location.pathname;
  const isLanguagePath =
    currentPath.startsWith("/en") || currentPath.startsWith("/fr");

  return (
    <Routes>
      {/* Only transform paths when a language is already detected */}
      {React.Children.map(children, (child) =>
        isLanguagePath ? transformChild(child) : child
      )}
    </Routes>
  );
};

export default LocalizedRoutes;
